.publications-header {
    font-family: 'IBM Plex Sans';
    font-weight: bold;
}

.publication-row {
    display: flex;
    flex-direction: row;
}

.publication-row img {
    height: 48px;
    margin-right: 16px;
}

.publication-view {
    padding: 4px 8px;
    background-color: rgb(236, 236, 236);
    border-radius: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}

.publication-view h5 {
    font-family: 'IBM Plex Sans';
    margin: 4px 4px 0px 0px;
    font-weight: 600;
}

.publication-view h3 {
    font-family: 'IBM Plex Sans';
    margin: 0px;
    font-size: medium;
}

.pub-med-div {
    margin-left: auto;
    display: flex;
    align-items: flex-end;
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    height: 100%;
}

.pub-med-div p {
    margin: 0px;
}

.pub-med-div p:hover {
    font-weight: bold;
    cursor: pointer;
}
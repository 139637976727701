.work-column {
    width: 192px;
    display: flex;
    flex-direction: column;
    margin: 4px;
}

.work-column h5 {
    margin: 0px;
    font-family: 'IBM Plex Sans';
    color: rgb(0, 45, 101);
    font-weight: 400;
}

.work-column img {
    height: 64px;
    object-fit: contain;
    margin: 8px 0px;
}

.work-view {
    background-color: rgb(211, 231, 255);
    border-radius: 12px;
    padding: 8px;
}

.work-view h2 {
    margin: 4px 0px 4px 0px;
    font-family: 'IBM Plex Sans';
    color: rgb(0, 45, 101);
    font-size: large;
    height: 68px;


    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
}

work-bio-text {
    font-family: 'IBM Plex Sans';
}
.gradient-header {

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.header-mobile-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-45p {
    width: 45%;
}

.text-55p {
    width: 55%;
    text-align: left;
}

@media (max-width: 900px) {
    .header-mobile-flex {
        flex-direction: column;
    }

    .card-45p {
        width: 100%;
        scale: 75%;
    }

    .text-55p {
        width: 100%;
    }

    .header-title-name {
        text-align: center;
    }

    .picture-cards {
        height: 250px !important;
    }
}

.header-title-name {
    color: white;
    font-family: 'IBM Plex Sans';
    font-size: 60px;
    line-height: 1rem;
}

.picture-cards {
    display: flex;
    position: relative;
    height: 350px;
    padding: 32px;
}

.bio-text-view {
    /* background-color: rgb(236, 243, 255); */
    background-color: #273c75;
    padding: 8px 16px;
    border-radius: 8px;
}

.bio-text {
    text-align: left;
    font-family: 'IBM Plex Sans';
    /* color: rgb(0, 0, 97); */
    color: white;
}

.card {
    position: absolute;
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.5s ease;
}

.card-0 {
    z-index: 3;
    transform: translateY(60px) rotate(0deg);
}

.card-1 {
    z-index: 2;
    transform: translateY(-10px) translateX(-20px) rotate(-5deg);
}

.card-2 {
    z-index: 1;
    transform: translateY(130px) translateX(20px) rotate(5deg);
}

.card:hover {
    cursor: pointer;
}

.container {
    margin: 0px auto;
    max-width: 1200px;
    padding: 4px 32px 12px 32px;
}

.consultation-button {
    padding: 8px 8px;
    background-color: #ebf1ff;
    font-family: 'IBM Plex Sans';
    border: 0px;
    border-radius: 8px;
    width: 100%;
    margin: 8px 0px;
    font-weight: 800;
    font-size: large;
    color: #192a56;
}

.consultation-button:hover {
    opacity: 0.8;
    cursor: pointer;
}